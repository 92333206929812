import { useContext, useRef, useEffect, useMemo, useState } from 'react';
import { AppContext } from 'Context';
import useSWR from 'swr';
import Paper from '@mui/material/Paper';
import { axios, dataFetcher, endpoints, baseUrl } from 'Api';
import Table from '@mui/material/Table';
import { ref, get, getDatabase, off, onValue, update } from 'firebase/database';
import { toast } from 'react-toastify';
import Spinner from 'Components/Spinner';
import ListItemText from '@mui/material/ListItemText';
import { v4 as uuid } from 'uuid';
import AWS from 'aws-sdk';
import { saveUploadInfo, saveUploadDetails, saveTitle, saveDummy, removeDummy, removeTitle } from 'store/store';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { useSelector, useDispatch } from 'react-redux';
import noImageAvailable from 'Assets/noimage-available.png';
import generatingThumbnail from 'Assets/generating-thumbnail.jpg';
import noPhotoAvailable from 'Assets/no-image-available.png';
import OutlinedInput from '@mui/material/OutlinedInput';
import TableHead from '@mui/material/TableHead';
import { useNavigate, useLocation } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import { ReactComponent as CloseIcon } from 'Assets/close.svg';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TableRow from '@mui/material/TableRow';
import { Checkbox as MuiCheckbox, withStyles } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons'; // Import the CheckIcon
import { Radio } from './upload-modal/components/Material-reDesign';
import { Checkbox, StyledCheckbox } from '../Pages/private/videos/components/CustomDesigns';
import { GroupSelector } from './group-selector/index';
import { awsConfig, generateS3Url, getBucketName, getBucketNamePath } from '../Api/awsConfig';
import styles from './Listing.module.css';
import { Pathname } from '../Routes';
import DeleteIcon from '../Assets/delete.png';
import SpinnerImage from '../Assets/Spinner-3.gif';

export default function ListingModal({ open, key, videoFiles, setVideoFiles, setOpen, setEditLoader }) {
  // const navigate = useNavigate();
  const { setAppSnackbar, s3Data, firebaseDatabase, setVideoUploadedParts, langText, userDetails,
    setUploadContainer, setIamUploading, setdisableDrag, setdidIOpenModal, subscriptionDetails,
    autoSignIn, setSelectedFiles, uploadQueue, setUploadQueue, activeUploads, setActiveUploads, MAX_VIDEOS, uploadProcessing,
    setUploadProcessing, setNavigateOnceClicked } = useContext(AppContext);
  const spinerSmall = 'spinex';
  const uploadPercentages = useSelector((state) => state.upload);
  const handleClose = (event) => {
    event.stopPropagation();
    setOpen(false);
  };
  const defaultVideoDetails = {
    name: undefined,
    duration: undefined,
    size: undefined,
  };
  const test = useSelector((state) => state.titleBulk);
const dispatch = useDispatch();
const updateTitle = (draft_id, data, status = 1) => {
  if (status === 1) {
    dispatch(saveTitle({ [draft_id]: data }));
  } else {
    dispatch(removeTitle(draft_id));
  }
};
  const getToken = () => localStorage.getItem('token');
  const videoRef = useRef(null);
  const databaseRef = ref(firebaseDatabase, 'status_from_sns');
  const userId = userDetails?.isSubscription?.user_id;
  const [t, setT] = useState(false);
  const [videoDetails, setVideoDetails] = useState(defaultVideoDetails);
  const [videoGroup, setVideoGroup] = useState([]);
  const [videoVisibility, setVideoVisibility] = useState(null);
  const [options, setOptions] = useState([]);
  const [videoThumbnails, setVideoThumbnails] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const [thumbnailGenerationStatus, setThumbnailGenerationStatus] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isItDelete, setisItDelete] = useState(true);
  const [buttonText, setButtonText] = useState('Save to Draft');
  const [videoThumbnails64, setVideoThumbnails64] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  function formatFileSizeAccMb(sizeInBytes) {
    const megabyte = 1024 * 1024;
    const sizeInMB = sizeInBytes / megabyte;
    return sizeInMB.toFixed(2);
  }
  const [selectedItems, setSelectedItems] = useState(Array(videoFiles?.length).fill({
    token: getToken(),
    group_ids: [],
    draftId: '',
    thumbnails: '',
    name: '',
    description: '',
    category: '',
    playlist: [],
    visibility: '2',
    size: '',
  }));
  useEffect(() => {
    // eslint-disable-next-line
    videoFiles.forEach((file) => {
      // eslint-disable-next-line
      file.draftId = uuid();
    });
  }, [updateTrigger]);
  useEffect(() => {
    const statuses = Object.values(thumbnailGenerationStatus);
    const anyGenerating = statuses.some((status) => status === 'generating');
    if (anyGenerating && isItDelete) {
      setIsLoading(true);
    } else {
      const allSuccessOrFailed = statuses.every((status) => status === 'success' || status === 'failed');
      if (allSuccessOrFailed) {
        setIsLoading(false);
      }
    }
  }, [thumbnailGenerationStatus]);
  useEffect(() => {
    const allItems = videoFiles.map((obj, i) => ({
      token: getToken(),
      group_ids: [],
      draftId: obj?.draftId,
      thumbnails: videoThumbnails[i],
      name: obj.name,
      description: selectedItems[i]?.description,
      category: selectedItems[i]?.category,
      playlist: selectedItems[i]?.playlist,
      visibility: selectedItems[i]?.visibility,
      size: formatFileSizeAccMb(obj.size)
    }));
    setSelectedItems(allItems);
  }, [videoFiles]);

  const selectGroup = (id, index) => {
    setSelectedItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index].group_ids = videoGroup.includes(id)
        ? videoGroup.filter((groupId) => groupId !== id)
        : [...videoGroup, id];
      return updatedItems;
    });
    setVideoGroup((prevVideoGroup) => (
      prevVideoGroup.includes(id)
        ? prevVideoGroup.filter((groupId) => groupId !== id)
        : [...prevVideoGroup, id]
    ));
  };
  const iteratorNum = [];
  const totalNum = [];
  let tempPercentages;
  const formDataJsonString = [];
  AWS.config.update({
    region: awsConfig.region,
    accessKeyId: awsConfig.accessKey,
    secretAccessKey: awsConfig.secretKey,
  });
  AWS.config.httpOptions.timeout = 0;
  const s3 = new AWS.S3();
  const { isValidating: gettingPlaylists, mutate: getPlaylists } = useSWR(endpoints.getMyPlaylist, {
    onSuccess: ({ success, data }) => {
      if (success) {
        setOptions(data?.map(({ id, title }) => ({ name: title, value: id })));
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while fetching you playlists' });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while fetching you playlists' });
    },
  });
  const [categrory, setCategrory] = useState(undefined);
  const { isValidating: fetchingCategories, mutate: fetchCategories } = useSWR([endpoints.getMyCategories], {
    fetcher: (url) => dataFetcher(url),
    onSuccess: ({ success, data }) => {
      if (success) {
        setCategrory(data?.map(({ title: name, id: value }) => ({ name, value })));
      }
    },
    onError: () => { },
  });
  useEffect(() => {
    setEditLoader(false);
    getPlaylists();
    fetchCategories();
    setdisableDrag(false);
    setButtonText('Save to Draft');
    setIsLoading(false);
    setisItDelete(true);
    setThumbnailGenerationStatus({});
    // setdidIOpenModal(true);
    return () => {
      // setdisableDrag(true);
      setdidIOpenModal(false);
    };
  }, []);

  const updateUploadDetails = (draft_id, data) => {
    dispatch(saveUploadDetails({ [draft_id]: data }));
  };

const updateItem = (percentage, draft_id, filename, uploadPercentages, updateUploadPercentages) => {
  const percent = parseInt((percentage / totalNum[draft_id]) * 100, 10);
  const trimmedFilename = filename.length > 26 ? `${filename.substring(0, 26)}...` : filename;
    const newPercentages = { ...uploadPercentages, [draft_id]: percent };
    tempPercentages = newPercentages;
    updateUploadPercentages(draft_id, percent);
    toast.update(draft_id, { render: `${trimmedFilename} ${percent}%`, autoClose: false });
};
const closeToast = (draft_id, message, type) => {
  toast.update(draft_id, { closeButton: false, render: message, type: type === 'success' ? toast.TYPE.SUCCESS : toast.TYPE.ERROR, autoClose: 3000 });
  setUploadContainer((prevUploadContainer) => prevUploadContainer.filter((id) => id !== draft_id));
};
async function uploadPart(bucket, key, partNumber, partBody, uploadId, setVideoUploadedParts, filename, draft_id, uploadPercentages, updateUploadPercentages, partSize, startTime, fileSize) {
  const params = {
    Bucket: bucket,
    Key: key,
    PartNumber: partNumber,
    UploadId: uploadId,
    Body: partBody,
  };
  return s3.uploadPart(params)
    .promise()
    .then((data) => {
      const currentTime = new Date().getTime();
      const elapsedSize = (partNumber * partSize) / 1024 / 1024;
      const elapsedTime = (currentTime - startTime) / 1000;
      const remainingSize = (fileSize / 1024 / 1024) - elapsedSize;
      updateUploadDetails(draft_id, { elapsed_size: elapsedSize, elapsed_time: elapsedTime, remaining_size: remainingSize > 0 ? remainingSize : 0 });
      iteratorNum[draft_id] += 1;
      updateItem(iteratorNum[draft_id], draft_id, filename, uploadPercentages, updateUploadPercentages);
      setVideoUploadedParts((prevState = []) => [...prevState, partNumber]);
      return data;
    })
    .catch((error) => {
      console.error(`AWS Multipart Upload : Error uploading part ${partNumber}:`, error);
      throw error; // Rethrow the error to be handled by the caller
    });
}

async function sendVideoDataToServer(theGreatPayload, draft_id) {
  const apiUrl = `${baseUrl}/V6/addVideo`;
  try {
    const response = await axios.post(apiUrl, theGreatPayload);
    closeToast(draft_id, 'Uploading completed', 'success');
  } catch (error) {
    console.error('Error sending video data:', error);
  }
}
async function completeMultipartUpload(bucket, key, parts, uploadId, file, index) {
  const params = {
    Bucket: bucket,
    Key: key,
    MultipartUpload: {
      Parts: parts,
    },
    UploadId: uploadId,
  };
  try {
    const response = await s3.completeMultipartUpload(params).promise();
    let videoDuration = 0;
    let videoHeightI = 0;
    let videoWidthI = 0;
    try {
      const videoElement = document.createElement('video');
      videoElement.src = URL.createObjectURL(file);
      await videoElement?.load();
      videoElement.muted = true;
      await videoElement?.play();
      videoDuration = videoElement?.duration;
      videoHeightI = videoElement?.videoHeight;
      videoWidthI = videoElement?.videoWidth;
      videoElement?.pause();
    } catch (e) {
      console.error('Error in getting width and height:', e);
    }
    const formData = new FormData();
    const s3Url = generateS3Url();
    formData.append('token', getToken());
    formData.append('draft_id', file?.draftId);
    videoThumbnails[index] && formData.append('thumbnail', videoThumbnails[index]);
    formData.append('title', test[file?.draftId]);
    formData.append('video_duration', videoDuration);
    formData.append('videoOreintation', videoHeightI < videoWidthI ? 1 : 2);
    formData.append('videoHeight', videoHeightI);
    formData.append('videoWidth', videoWidthI);
    // formData.append('description', selectedItems[index].description);
    selectedItems[index].category ? formData.append('category_id', selectedItems[index].category) : null;
    formData.append('playlist_ids', selectedItems[index]?.playlist?.map((item) => item?.value));
    formData.append('visibility', selectedItems[index].visibility);
    formData.append('group_ids', selectedItems[index]?.group_ids);
    formData.append('s3_url', `${s3Url}${getBucketNamePath(userId)}/${key}`);
    formData.append('s3_image_bucket_url', s3Url);
    formData.append('s3_status', 'Completed');
    formData.append('bucket_folder_path', `${getBucketNamePath(userId)}/${key}`);
    formData.append('video_size', formatFileSizeAccMb(file.size));
    closeToast(file?.draftId, 'Uploading completed', 'success');
    sendVideoDataToServer(formData, file?.draftId);
    if (subscriptionDetails?.current_plan === 'Free') {
      autoSignIn();
    }
    if (uploadQueue?.length <= 1) {
      setOpen();
        }
    return response;
  } catch (error) {
    console.error('Error completing multipart upload:', error);
    if (subscriptionDetails?.current_plan === 'Free') {
      autoSignIn();
    }
    throw error; // Rethrow the error to be handled by the caller
  }
}

const convertToBase64 = async (file) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

function base64ToFile(base64String, fileName) {
  const byteString = atob(base64String);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i += 1) {
    uint8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([arrayBuffer], { type: 'image/jpeg' });
  const file = new File([blob], fileName, { type: 'image/jpeg' });
  return file;
}

const ChangeButton = (newCategoryStates, updatedVideoFiles, singleArray) => {
  const mergeArrays = (array1, array2) => {
    const array2Map = new Map(array2.map((item) => [item?.draftId, item]));
    const result = array1.filter((item) => !array2Map.has(item?.draftId)).concat(array2);
    return result;
};
const filteredNewCategoryStates = singleArray === 'singleArray' ? mergeArrays(newCategoryStates, selectedItems) : newCategoryStates?.filter((categoryState) => updatedVideoFiles?.some((videoFile) => videoFile.draftId === categoryState.draftId));
  let c = 0;
  let t = 0;
  newCategoryStates.forEach((item, index) => {
    if (item?.category !== '') {
      c += 1;
    }
  });
  videoThumbnails64.forEach((item, index) => {
    if (item !== undefined) {
      t += 1;
    }
  });
  const allHaveCategory = filteredNewCategoryStates?.every((item) => item.category && item.category !== '');
  const someHaveCategory = filteredNewCategoryStates?.some((item) => item.category && item.category !== '');
  if (allHaveCategory) {
    setButtonText('Publish');
  } else if (someHaveCategory) {
    setButtonText('Publish & Save');
  } else {
    setButtonText('Save to Draft');
  }
};

const mediaThumGenerator = (file, index) => {
  const video = document.createElement('video');
  video.src = URL.createObjectURL(file);
  setThumbnailGenerationStatus((prev) => ({ ...prev, [file.draftId]: 'generating' }));
  video.addEventListener('seeked', () => {
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
    canvas.toBlob((blob) => {
      // Create a file from the blob
      const thumbnailFile = new File([blob], `thumbnail_${index}.jpg`, { type: 'image/jpeg' });
      // Now you can use the thumbnailFile as needed
      // Assuming you have a function like setVideoThumbnails to update state
      setVideoThumbnails((prevThumbnails) => {
        const updatedThumbnails = [...prevThumbnails];
        updatedThumbnails[index] = thumbnailFile;
        return updatedThumbnails;
      });
      convertToBase64(thumbnailFile)
          .then((base64String) => {
            if (base64String.length > 1000) {
              setVideoThumbnails((prevThumbnails) => {
                const updatedThumbnails = [...prevThumbnails];
                updatedThumbnails[index] = thumbnailFile;
                return updatedThumbnails;
              });
              setThumbnailGenerationStatus((prev) => ({ ...prev, [file.draftId]: 'success' }));
              setVideoThumbnails64((prevThumbnails) => {
                const updatedThumbnails = [...prevThumbnails];
                updatedThumbnails[index] = base64String;
                return updatedThumbnails;
              });
            } else {
              setThumbnailGenerationStatus((prev) => ({ ...prev, [file.draftId]: 'fallback' }));
              setVideoThumbnails((prevThumbnails) => {
                const updatedThumbnails = [...prevThumbnails];
                updatedThumbnails[index] = generatingThumbnail;
                return updatedThumbnails;
              });
              const videoFilePiece = new FormData();
              videoFilePiece.append('video_file', file.slice(0, 5 * 1024 * 1024));
              const apiUrl = 'https://conf.eq7.in/generate-thumbnail/';
              fetch(apiUrl, {
                method: 'POST',
                body: videoFilePiece,
              })
                .then((response) => response.json())
                .then((data) => {
                  if (data?.thumbnail) {
                    setVideoThumbnails64((prevThumbnails) => {
                      const updatedThumbnails = [...prevThumbnails];
                      updatedThumbnails[index] = `data:image/png;base64, ${data?.thumbnail}`;
                      return updatedThumbnails;
                    }).then((e) => {
                      ChangeButton(selectedItems, videoFiles);
                    });
                    const newThumbnailFile = base64ToFile(data?.thumbnail, `thumbnail_${index}.jpg`);
                    setVideoThumbnails((prevThumbnails) => {
                      const updatedThumbnails = [...prevThumbnails];
                      updatedThumbnails[index] = newThumbnailFile;
                      return updatedThumbnails;
                    });
                    setThumbnailGenerationStatus((prev) => ({ ...prev, [file.draftId]: 'success' }));
                  } else {
                    setVideoThumbnails((prevThumbnails) => {
                      const updatedThumbnails = [...prevThumbnails];
                      updatedThumbnails[index] = undefined;
                      return updatedThumbnails;
                    });
                    setThumbnailGenerationStatus((prev) => ({ ...prev, [file.draftId]: 'failed' }));
                  }
                })
                .catch((error) => {
                  console.error('Error uploading file', error);
                  setVideoThumbnails((prevThumbnails) => {
                    const updatedThumbnails = [...prevThumbnails];
                    updatedThumbnails[index] = undefined;
                    return updatedThumbnails;
                  });
                  setThumbnailGenerationStatus((prev) => ({ ...prev, [file.draftId]: 'failed' }));
                });
            }
          })
          .catch((error) => {
            console.error('Error converting thumbnail to base64:', error);
            setThumbnailGenerationStatus((prev) => ({ ...prev, [file.draftId]: 'failed' }));
          });
    }, 'image/jpeg', 0.9);
  });
  video.addEventListener('loadeddata', () => {
    const interval = video.duration / 20;
    video.currentTime = interval;
  });
  setTimeout(() => {
    setThumbnailGenerationStatus((prev) => ({ ...prev, [file.draftId]: 'success' }));
  }, 45000);
};
function formatFileSize(sizeInBytes) {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;
  const gigabyte = megabyte * 1024;
  if (sizeInBytes >= gigabyte) {
    return `${(sizeInBytes / gigabyte).toFixed(2)} GB`;
  }
  if (sizeInBytes >= megabyte) {
    return `${(sizeInBytes / megabyte).toFixed(2)} MB`;
  }
  if (sizeInBytes >= kilobyte) {
    return `${(sizeInBytes / kilobyte).toFixed(2)} KB`;
  }
  return `${sizeInBytes} Bytes`;
}

useEffect(() => {
  videoFiles.map((obj, i) => {
    const { draftId } = obj;
    const fileNameWithoutExtension = obj.name.split('.').slice(0, -1).join('.');

    if (test[draftId]) {
      updateTitle(draftId, test[draftId]);
    } else {
      updateTitle(draftId, fileNameWithoutExtension);
    }

    if (!videoThumbnails[i] || videoThumbnails[i].length < 2) {
      mediaThumGenerator(obj, i);
    }
    return false;
  });
  setT(true);
}, [videoFiles]);

const handleCheckboxChange = (name, size) => {
  setSelectedItems((prevItems) => {
    const isSelected = prevItems.some((item) => item.name === name);
    if (isSelected) {
      return prevItems.filter((item) => item.name !== name);
    }
    return [
      ...prevItems,
      {
        token: getToken(),
        draftId: '',
        group_ids: [],
        thumbnails: '',
        name,
        description: '',
        category: '',
        playlist: [],
        visibility: '',
        size,
      },
    ];
  });
};

const handlePlaylistChange = (event, index) => {
  setSelectedItems((prevItems) => {
    const newItems = [...prevItems];
    newItems[index] = {
      ...newItems[index],
      playlist: event.target.value,
    };
    return newItems;
  });
};
const [arrayUpdater, setarrayUpdater] = useState(false);
const handleCategoryChange = (event, index) => {
  const newCategoryStates = [...selectedItems];
  const selectedCategoryName = categrory.find(
    (categoryOption) => categoryOption.value === event.target.value
  )?.name || 'Default';
  newCategoryStates[index] = {
    ...newCategoryStates[index],
    category: event.target.value,
    categoryName: selectedCategoryName,
  };
  ChangeButton(newCategoryStates, videoFiles);
  setSelectedItems(newCategoryStates);
  setarrayUpdater(!arrayUpdater);
};

const handleDescriptionChange = (event, index) => {
  const { value } = event.target;
  setSelectedItems((prevItems) => prevItems.map((item, i) => (i === index ? { ...item, description: value } : item)));
};
const visibilityText = langText?.visibility;
const videoVisibilityOptions = [
  { name: 'All Viewers' || visibilityText?.publicDescription, value: '2' },
  { name: visibilityText?.privateDescription || 'Only I can view (Private)', value: '1' },
  { name: 'Choose a group', value: '3' },
];
const [openGroups, setOpenGroups] = useState(Array(selectedItems.length).fill(false));

const handleVisibilityChange = (event, index) => {
  const newVisibilityStates = [...selectedItems];
  newVisibilityStates[index] = {
    ...newVisibilityStates[index],
    visibility: event.target.value
  };
  const newOpenGroups = Array.isArray(openGroups) ? [...openGroups] : [];
  newOpenGroups[index] = event.target.value === '3';
  setSelectedItems(newVisibilityStates);
  setOpenGroups(newOpenGroups);
};
const handleMenuItems = (index, i) => {
  const newOpenGroups = [...openGroups];
  newOpenGroups[i] = true;
  if (index === 2) {
  setOpenGroups(newOpenGroups);
} else {
  setOpenGroups(false);
}
  };
const handleMenuItemClick = (index) => {
  const newOpenGroups = [...openGroups];
  newOpenGroups[index] = false;
  setOpenGroups(newOpenGroups);
};
const updateUploadPercentages = (draft_id, data) => {
  dispatch(saveUploadInfo({ [draft_id]: data }));
};
const notify = (filename, draft_id, uploadPercentages, updateUploadPercentages) => {
  setTimeout(() => {
    const newPercentages = { ...uploadPercentages, [draft_id]: 'Starting Upload' };
    tempPercentages = newPercentages;
    updateUploadPercentages(draft_id, 'Starting Upload');
    const trimmedFilename = filename.length > 26 ? `${filename.substring(0, 26)}...` : filename;
    toast(trimmedFilename, {
      autoClose: false,
      toastId: draft_id,
      position: toast.POSITION.BOTTOM_RIGHT,
      closeButton: <CustomCloseButton draft_id={draft_id} updateUploadPercentages={updateUploadPercentages} />,
      toastClassName: 'custom-toast'
    });
  }, 0);
};
async function uploadPartSequentially(file, bucket, key, uploadId, partNumber, start, partSize, parts, draft_id, startTime) {
  const end = Math.min(start + partSize, file.size);
  const data = {};
  const dataCallback = (snapshot) => {
    if (snapshot.exists()) {
      const dataObj = snapshot.val();
      data[draft_id] = dataObj[draft_id];
    }
  };
  onValue(databaseRef, dataCallback);
  const unsubscribe = () => {
    off(databaseRef, 'value', dataCallback);
  };
  if (data[draft_id]?.status !== 'DELETED') {
    await uploadPart(getBucketName(userId), key, partNumber, file.slice(start, end), uploadId, setVideoUploadedParts, file.name, draft_id, uploadPercentages, updateUploadPercentages, partSize, startTime, file.size)
    .then((item) => {
      parts.push({ PartNumber: partNumber, ETag: item.ETag });
    });
  } else {
    unsubscribe();
  }
  unsubscribe();
  if (end < file.size) {
    await uploadPartSequentially(file, bucket, key, uploadId, partNumber + 1, end, partSize, parts, draft_id, startTime);
  }
}

async function initMultipartUpload(bucket, key) {
  const params = {
    Bucket: bucket,
    Key: key,
  };

  return s3.createMultipartUpload(params).promise();
}

const updateDummy = (dummy, status = 0) => {
  if (status === 1) {
    dispatch(saveDummy({ [dummy?.draft_id]: dummy }));
  } else {
    dispatch(removeDummy(dummy));
  }
};
const handleEncouter = (draft_id, updateUploadPercentages) => {
  if (uploadQueue?.length <= 1) {
    setOpen();
      }
  toast.update(draft_id, {
    closeButton: <CustomCloseloader />,
  });

  const databaseRef = ref(firebaseDatabase, 'status_from_sns');
  const changeFieldFromFirebase = (draft_id, statusString) => {
    const dataToUpdate = {
      [draft_id]: {
        status: statusString,
      },
    };
    update(databaseRef, dataToUpdate);
  };
  changeFieldFromFirebase(draft_id?.draft_id ? draft_id?.draft_id : draft_id, 'DELETED');
  updateDummy(draft_id?.draft_id ? draft_id?.draft_id : draft_id, 0);
  setIamUploading(false);
  setActiveUploads((prevUploads) => prevUploads?.filter((id) => id !== draft_id));
  updateUploadPercentages(draft_id?.draft_id ? draft_id?.draft_id : draft_id, 'Cancelled');
  closeToast(draft_id?.draft_id ? draft_id?.draft_id : draft_id, 'Upload has been canceled by user', 'error');
};

const backToOld = ({ draft_id, updateUploadPercentages }) => {
  toast.update(draft_id, {
    closeButton: <CustomCloseButton draft_id={draft_id} updateUploadPercentages={updateUploadPercentages} />,
  });
};
const CustomCloseDiv = (draft_id, updateUploadPercentages) => (
  <div className={styles.customDiv}>
    Are you sure you want to cancel ?
    <div className={styles.fleo}>
      <button className={styles.cancelPlz} onClick={() => backToOld(draft_id, updateUploadPercentages)}>
      Exit
      </button>
      <button className={styles.noCancel} onClick={() => handleEncouter(draft_id, updateUploadPercentages)}>
        Continue to cancel
      </button>
    </div>
  </div>
);
const handleClick = (draft_id, updateUploadPercentages) => {
  toast.update(draft_id, {
    closeButton: <CustomCloseDiv draft_id={draft_id} updateUploadPercentages={updateUploadPercentages} />,
  });
};
const CustomCloseloader = () => (
  <button className={styles.closeMeBro}>
  <Spinner />
  </button>
);

const CustomCloseButton = ({ draft_id, updateUploadPercentages }) => (
  <button className={styles.closeMeBro} onClick={() => handleClick(draft_id, updateUploadPercentages)}>
    <CloseIcon />
  </button>
);

async function uploadLargeFile(bucket, key, file, draft_id, index, fileName) {
  setUploadContainer((prevUploadContainer) => [...prevUploadContainer, draft_id]);
  try {
    notify(file.name, draft_id, uploadPercentages, updateUploadPercentages);
    const { UploadId } = await initMultipartUpload(bucket, fileName);
    const minimumPartSize = 5 * 1024 * 1024; // 5MB -- aws s3 bucket requires atlease 5MB/packet currently
    let partSize = file.size / 100; // splitting to 100 packets to perform 1 packet as for 1 percent
    if (partSize < minimumPartSize) {
      partSize = minimumPartSize;
    }
    const fileSize = file.size;
    const partCount = Math.ceil(fileSize / partSize);
    const startTime = new Date().getTime();
    const parts = [];
    iteratorNum[draft_id] = 0;
    totalNum[draft_id] = partCount;
    await uploadPartSequentially(file, bucket, fileName, UploadId, 1, 0, partSize, parts, draft_id, startTime);
    const data = {};
    const dataCallback = (snapshot) => {
      if (snapshot.exists()) {
        const dataObj = snapshot.val();
        data[draft_id] = dataObj[draft_id];
      }
    };
    onValue(databaseRef, dataCallback);
    const unsubscribe = () => {
      off(databaseRef, 'value', dataCallback);
    };
    if (data[draft_id]?.status !== 'DELETED') {
      const uploadedData = await completeMultipartUpload(bucket, fileName, parts, UploadId, file, index);
      const newPercentages = { ...uploadPercentages, [draft_id]: 'Please wait' };
      tempPercentages = newPercentages;
      updateUploadPercentages(draft_id, 'Please wait');
      iteratorNum[file.name] = 0;
      totalNum[draft_id] = 0;
      setIamUploading(true);
    } else {
      updateUploadPercentages(draft_id, 'Cancelled');
      closeToast(draft_id, 'Upload has been canceled by user', 'error');
      unsubscribe();
    }
    unsubscribe();
  } catch (error) {
    if (localStorage.getItem('opened_draft_id') === draft_id) {
      // hideModal();
    }
    updateUploadPercentages(draft_id, 'Cancelled');
    closeToast(draft_id, 'Upload has been canceled by user', 'error');
    const newPercentages = { ...uploadPercentages, [draft_id]: 'Cancelled' };
    tempPercentages = newPercentages;
    updateUploadPercentages(draft_id, 'Cancelled');
    updateUploadDetails(draft_id, { elapsed_size: 0, elapsed_time: 0, remaining_size: 0 });
    const allPropertiesAreNumericOrString = Object.values(uploadPercentages).some((value) => typeof value === 'number' || value === 'Starting Upload' || value === 'Initializing');
    if (allPropertiesAreNumericOrString) {
      setIamUploading(false);
    } else setIamUploading(true);
  }
}

function changeSize(size) {
  const newSize = size / 1024 / 1024;
  return newSize.toFixed(2);
}

async function processUploadQueue() {
  if (activeUploads.length < 5 && uploadQueue.length > 0) {
    // Get the next file from the queue
    const nextUpload = uploadQueue[0];

    // Start the upload and remove it from the queue
    setActiveUploads((prevUploads) => [...prevUploads, nextUpload.draftId]);
    setUploadQueue((prevQueue) => prevQueue.slice(1));

    await uploadLargeFile(getBucketName(userId), nextUpload.file.name, nextUpload.file, nextUpload.draftId, nextUpload.index, nextUpload.fileName);
    // Remove from active uploads once completed
    setActiveUploads((prevUploads) => prevUploads.filter((id) => id !== nextUpload.draftId));
  }
}

useEffect(() => {
  if (uploadQueue.length > 0 && activeUploads.length < 5) {
    processUploadQueue();
  }
}, [uploadQueue, activeUploads]);

async function handleInitMultipartUpload() {
  // setAppSnackbar({ isVisible: true, type: 'error', message: 'Please wait for all thumbnails to generate.' });
  // setOpen(false);
  setSelectedFiles([]);
  videoFiles.forEach((file, index) => {
    const fileExtension = file.name.split('.').pop().toLowerCase();
    const number = Math.floor(Math.random() * 10000) + 1;
    const fileName = `${Date.now()}-${userId}-video-${number}.${fileExtension}`;
    // Add file to the upload queue
    setUploadQueue((prevQueue) => [
      ...prevQueue,
      { file, index, fileName, draftId: file.draftId }
    ]);
    const currentDate = new Date();
    const date = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const formattedDate = `${String(month).padStart(2, '0')}/${String(date).padStart(2, '0')}/${year}`;
    let type_of;
    let contentStatus;
    if (selectedItems[index].category && videoThumbnails[index] && selectedItems[index].visibility && videoThumbnails64[index] !== undefined) {
      type_of = 0;
      contentStatus = 'Video';
    } else {
      type_of = 2;
      contentStatus = 'Draft';
    }
    const dummy = {
      draft_id: file.draftId,
      title: test[file.draftId] || fileName,
      thumbnail: videoThumbnails64[index],
      category: selectedItems[index].categoryName,
      video_description: selectedItems[index].description,
      created: formattedDate,
      video_size: changeSize(file?.size),
      type: type_of,
      group_id: selectedItems[index]?.group_ids,
      content_status: contentStatus,
      uploadIsTrying: 1,
    };
    updateDummy(dummy, 1);
  });
  processUploadQueue();
  setIsVisible(false);
  setNavigateOnceClicked(true);
  // setTimeout(() => {
  // }, 300);
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const handleDelete = (id, index) => {
  const updatedVideoThumbnails = [...videoThumbnails];
  const updatedVideoThumbnails64 = [...videoThumbnails64];
  updatedVideoThumbnails.splice(index, 1);
  updatedVideoThumbnails64.splice(index, 1);
  setVideoThumbnails(updatedVideoThumbnails);
  setVideoThumbnails64(updatedVideoThumbnails64);
  updateTitle(index, null, 0);
  const updatedSelectedItems = selectedItems.filter((item) => item?.draftId !== id);
  setSelectedItems(updatedSelectedItems);
  const updatedVideoFiles = videoFiles.filter((file) => file.draftId !== id);
  setVideoFiles(updatedVideoFiles);
  if (updatedVideoFiles.length === 0) {
    setOpen();
  }
  ChangeButton(selectedItems, updatedVideoFiles);
  setarrayUpdater(!arrayUpdater);
};
const [inputTrigger, setInputTrigger] = useState(false);

const MAX_SIZE_GB = 30;
const MAX_SIZE_BYTES = MAX_SIZE_GB * 1024 * 1024 * 1024;
const allowedExtensions = ['mp4', 'mov', 'mpg', 'm4v', 'm2ts'];
const handleFileChange = (event) => {
  const newFiles = Array.from(event.target.files);
  // Add draftId and visibility to new files
  newFiles.forEach((file) => {
    // eslint-disable-next-line
    file.draftId = uuid();
    // eslint-disable-next-line
    file.visibility = '2';
  });

  // Check for invalid file formats
  const invalidFiles = newFiles.filter((file) => {
    const extension = file.name.split('.').pop().toLowerCase();
    return !allowedExtensions.includes(extension);
  });

  if (invalidFiles.length > 0) {
    setAppSnackbar({ isVisible: true, type: 'error', message: 'Invalid file format. Allowed formats are: .mp4, .mov, .mpg, .m4v, .m2ts' });
    // alert('Invalid file format. Allowed formats are: .mp4, .mov, .mpg, .m4v, .m2ts');
    return;
  }

  const combinedFiles = [...videoFiles, ...newFiles];
  const totalSize = combinedFiles.reduce((acc, file) => acc + file.size, 0);
  if (combinedFiles.length > MAX_VIDEOS) {
    setAppSnackbar({ isVisible: true, type: 'error', message: `You can only select ${MAX_VIDEOS} videos` });
    return;
  }

  if (totalSize > MAX_SIZE_BYTES) {
    setAppSnackbar({ isVisible: true, type: 'error', message: `Total file size should not exceed ${MAX_SIZE_GB}GB.` });
    // eslint-disable-next-line
    event.target.value = '';
    return;
  }

  // Update state with valid files
  setSelectedItems((prevItems) => [
    ...prevItems,
    ...newFiles.map((file) => ({
      token: getToken(),
      group_ids: [],
      draftId: file.draftId,
      thumbnails: '',
      name: file.name,
      description: '',
      category: '',
      playlist: [],
      visibility: file.visibility,
      size: file.size,
    })),
  ]);

  setVideoFiles((prevFiles) => [...prevFiles, ...newFiles]);
  ChangeButton(combinedFiles, null, 'singleArray');
  // eslint-disable-next-line
  event.target.value = '';
};

const groupRefs = useRef([]);
const setGroupRef = (index) => (element) => {
  groupRefs.current[index] = element;
};
useEffect(() => {
  function handleClickOutside(event) {
    groupRefs.current.forEach((ref, index) => {
      if (ref && !ref.contains(event.target)) {
        const newOpenGroups = [...openGroups];
        newOpenGroups[index] = false;
        setOpenGroups(newOpenGroups);
      }
    });
  }
  document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
}, [openGroups, setOpenGroups]);
console.log('isLoading', isLoading);
  return (
    <div
    style={{
      display: isVisible ? 'block' : 'none', // Conditionally set display based on isVisible state
    }}
      className={styles.positioningModel}
      onKeyPress={undefined}
      role="button"
      tabIndex={0}
      // onClick={(event) => handleClose(event)}
    >
      <div
        onKeyPress={undefined}
        role="button"
        tabIndex={0}
        onClick={(event) => event.stopPropagation()}
        className={styles.bringCenter}
      >
        <div className={styles.heading}>
          Must Choose Video Category For Each Video In Order To Publish That Video
        </div>
        <div className={styles.headingh3}>
          To Add Subcategory, Change Thumbnail, And Add Video Description, Edit In Content Dashboard&gt;Content
        </div>
        <hr className={styles.linex} />
        <div className={styles.overScrollMe}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
          <TableRow>
            {/* <TableCell>
              <Checkbox
              status={selectedItems?.length === videoFiles?.length}
              />
            </TableCell> */}
            <TableCell>
              <div className={styles.TbleCel}>
              Content Preview
              </div>
            </TableCell>
            <TableCell className={styles.TbleCel}>
              <div className={styles.TbleCel}>
                Video Title
              </div>
            </TableCell>
            {/* <TableCell>
              Description
            </TableCell> */}
            <TableCell className={styles.TbleCel}>
              <div className={styles.TbleCel}>
            Category
              </div>
            </TableCell>
            {/* <TableCell>
              Playlist
            </TableCell> */}
            <TableCell className={styles.TbleCel}>
              <div className={styles.TbleCel}>
              Visiblity
              </div>
            </TableCell>
            <TableCell className={styles.TbleCel}>
              <div className={styles.TbleCel}>
              Size
              </div>
            </TableCell>
            <TableCell className={styles.TbleCel}>
              <div className={styles.TbleCel}>
              Actions
              </div>
            </TableCell>
          </TableRow>
          </TableHead>
         { t === true ? (
            <TableBody>
            {videoFiles?.map((obj, i) => (
              <TableRow key="" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                {/* <TableCell>
                  <Checkbox
                    status={selectedItems?.some((item) => item?.name === obj?.name)}
                    onClick={() => handleCheckboxChange(obj?.name, obj?.size)}
                  />
                </TableCell> */}
                <TableCell>
                <video ref={videoRef} style={{ display: 'none' }} controls />
                <div className={styles.previewContainer}>
                {videoThumbnails[i] ? (
                  <img
                    className={styles.previewImage}
                    src={(() => {
                      try {
                        return URL.createObjectURL(videoThumbnails[i]);
                      } catch (error) {
                        console.error('Failed to create object URL:', error);
                        return generatingThumbnail; // Return a default or fallback value
                      }
                    })()}
                    alt={obj?.title}
                  />
                )
                : (
                <img style={{ objectFit: 'contain' }} className={styles.previewImage} src={noImageAvailable} alt={obj?.title} />)}
                </div>
                </TableCell>
                <TableCell component="th" scope="row">
                  <div className={styles.nameBal}>
                      <input type="text" placeholder="Title" value={test[obj.draftId]} onChange={(e) => updateTitle(obj.draftId, e.target.value)} className={styles.titles} />
                  </div>
                </TableCell>
                {/* <TableCell component="th" scope="row">
                <textarea className={styles.description} placeholder="Description" value={selectedItems?.length > 0 ? selectedItems[i]?.description : ''} onChange={(e) => handleDescriptionChange(e, i)} />
                </TableCell> */}
                <TableCell>
                {categrory !== undefined
                  ? (
                <FormControl sx={{ m: 1, width: 120 }}>
                   <InputLabel htmlFor={`category-select-${i}`} color="primary">{categrory?.length < 0 ? 'No Category' : 'Category'}</InputLabel>
                  <Select
                    value={selectedItems[i] !== undefined && selectedItems[i]?.category}
                    onChange={(e) => handleCategoryChange(e, i)}
                    input={<OutlinedInput label="category" />}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {categrory?.length > 0
                    && categrory.map((categoryOption, index) => (
                      <MenuItem key={index} value={categoryOption.value || index + 1}>
                        {categoryOption.name || 'Default Name'}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                  )
                : <Spinner spinex={spinerSmall} />}
                </TableCell>
                {/* <TableCell>
                    {options?.length > 0 ? (
                      <FormControl sx={{ m: 1, width: 200 }}>
                         <InputLabel htmlFor={`playlist-select-${i}`} color="primary">Playlist</InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={selectedItems[i]?.playlist}
                          onChange={(e) => handlePlaylistChange(e, i)}
                          input={<OutlinedInput label="Playlist" />}
                          renderValue={(selected) => selected.map((item) => item.name).join(', ')}
                          MenuProps={MenuProps}
                        >
                          {selectedItems[i]?.playlist?.length <= 0 && ( // Render the placeholder only when no playlist is selected
                            <MenuItem value="" disabled>
                              Select Playlist
                            </MenuItem>
                          )}
                          {options && options?.map((option, index) => (
                            <MenuItem key={index} value={option || index + 1}>
                              <StyledCheckbox
                                checked={selectedItems[i]?.playlist?.includes(option) || false}
                              />
                              <ListItemText primary={option?.name || 'Default Name'} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : <Spinner spinex={spinerSmall} />}

                </TableCell> */}
                <TableCell>
                  <div className={styles.visibilityContainer}>
                       <FormControl sx={{ m: 1, width: 252 }}>
                          <Select
                            value={selectedItems[i]?.visibility || '2'}
                            onChange={(e) => handleVisibilityChange(e, i)}
                            inputProps={{ 'aria-label': 'Without label' }}
                          >
                          {videoVisibilityOptions?.map((option, index) => (
                            <MenuItem onClick={() => handleMenuItems(index, i)} key={index} value={option?.value || index + 1}>
                              {option?.name || 'Default Name'}
                            </MenuItem>
                          ))}
                          </Select>
                       </FormControl>
                        {/* {selectedItems[i].visibility === videoVisibilityOptions[2].value && ( */}
                        {openGroups[i] && (
                          <div ref={setGroupRef(i)}>
                            <GroupSelector
                              isBulk
                              onSelect={(val) => { selectGroup(val, i); }}
                              selectedOption={videoGroup}
                              setOpenGroups={setOpenGroups}
                              handleMenuItemClick={handleMenuItemClick}
                              index={i}
                            />
                          </div>
                        )}
                  </div>
                </TableCell>
                <TableCell>
                  <div className={styles.sizeBal}>
                {formatFileSize(obj.size)}
                  </div>
                </TableCell>
                <TableCell>
                <button disabled={isLoading} onClick={() => !isLoading && handleDelete(obj.draftId, i)} style={{ backgroundColor: 'transparent', cursor: isLoading ? 'default' : 'pointer', opacity: isLoading ? '0.5' : '1' }}>
                  <img src={DeleteIcon} alt="Remove" style={{ height: '25px', width: '25px' }} />
                </button>
                </TableCell>
              </TableRow>
            ))}
            </TableBody>
            )
            : (null) }
          </Table>
        </TableContainer>
        </div>
      <div className={styles.flexico}>
        <span style={{ fontSize: '14px' }} className={styles.heading}>
        {selectedItems?.length}
        {' '}
        Videos added
        </span>
        { videoFiles.length < MAX_VIDEOS
        && (
          <div className={styles.flexButton}>
            <label htmlFor="video-upload" className={styles.labelButton}>
              Add Files
            </label>
            <input onChange={handleFileChange} type="file" accept="video/*" multiple id="video-upload" className={styles.hiddenInput} />
          </div>
        )}
        <div className={styles.flexButtun}>
          <button
          onClick={() => { setOpen(false); setSelectedFiles([]); }}
          className={styles.cancel}
          >
            Cancel
          </button>
          <button
            disabled={isLoading}
            className={styles.save}
            style={{ cursor: isLoading ? 'default' : 'pointer', opacity: isLoading ? '0.5' : '1' }}
            onClick={!isLoading && handleInitMultipartUpload}
          >
            {buttonText}
          </button>
        </div>
      </div>
      </div>
    </div>
  );
}
